.carousel .control-next.control-arrow:before {
  border-left: 10px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 10px solid #000;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.6;
  padding: 10px;
}

.carousel.carousel-slider{
  overflow: visible;
}