@font-face {
    font-family: 'Lato';
    src: url(./assets/font/Lato-Regular.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(./assets/font/Lato-Bold.ttf) format('truetype');    
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: 'Lato';
    src: url(./assets/font/Lato-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/font/Lato-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

.shadow-rounded {
  -webkit-box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  -moz-box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

body,
html {
  font-family: 'Lato';
}

.Cal__Container__root {
  font-family: "Lato", sans-serif !important;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.overpass {
  font-family: "Lato" !important;
}


.MuiFilledInput-input {
  background-color: white !important;
  borderRadius: 6px;
}

.MuiAutocomplete-groupLabel {
  font-family: 'Lato';
  padding: 0;
}

.MuiAutocomplete-groupUl {
  border-top: 1px solid gray;
  //padding: 0 1em;
  //font-family: 'sansita-light' !important;
}
.MuiFormControlLabel-label {
  font-family: 'Lato' !important;
}

.MuiAutocomplete-groupUl li {
  //color: #009CA1 !important;
}

.MuiInputLabel-shrink {
  font-family: 'Lato';
  color: black;
  font-weight: bold !important;
}

a:-webkit-any-link {
text-decoration: none;
}

/* stripe */
.css-2kkxbv {
  padding: 0.75rem 0.75rem 0.75rem 0.5rem !important;
}

.MuiRating-iconFilled {
  color: gray;
  margin-bottom: 0;
  padding-bottom: 0;
}


